const Headline = ({ title, subline }) => (
	<div className="sm:text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl mb-10">
		<h2 className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl sm:tracking-tight">
			{title}
		</h2>
		{subline && (
			<h3 className="mt-2 mb-6 text-lg font-semibold text-gray-900 tracking-tight">
				{subline}
			</h3>
		)}
	</div>
);

export default Headline;
