import { faChartLine, faCommentsDollar, faCreditCard, faFileSignature, faHandshakeAngle, faMoneyBillTrendUp, faShip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Service = ({ service }) => (
	<li className="bg-white rounded-lg shadow-lg divide-y divide-gray-200 h-full relative">
		<div className="absolute top-0 p-2 inline-block bg-copper-main rounded-xl shadow-lg transform -translate-y-6 ml-6">
			<div className="w-8 h-8 flex justify-center items-center">
				{service.icon === 'faFileSignature' && <FontAwesomeIcon icon={faFileSignature} color="white" size="lg" /> }
				{service.icon === 'faHandshakeAngle' && <FontAwesomeIcon icon={faHandshakeAngle} color="white" size="lg" /> }
				{service.icon === 'faChartLine' && <FontAwesomeIcon icon={faChartLine} color="white" size="lg" /> }
				{service.icon === 'faCommentsDollar' && <FontAwesomeIcon icon={faCommentsDollar} color="white" size="lg" /> }
				{service.icon === 'faCreditCard' && <FontAwesomeIcon icon={faCreditCard} color="white" size="lg" /> }
				{service.icon === 'faShip' && <FontAwesomeIcon icon={faShip} color="white" size="lg" /> }
				{service.icon === 'faMoneyBillTrendUp' && <FontAwesomeIcon icon={faMoneyBillTrendUp} color="white" size="lg" /> }
			</div>
		</div>
		<div className="w-full flex items-center justify-between p-6 space-x-6">
			<div className="flex-1 relative">
				<div className="flex items-center space-x-3">
					<h3 className="text-gray-900 text-md font-medium pt-4">
						{service.title}
					</h3>
				</div>
				<p className="mt-1 text-gray-500 text-sm leading-6 font-thin">
					{service.text}
				</p>
			</div>
		</div>
	</li>
);

export default Service;
