const ProductCategory = ({ category }) => {
	const { title, products } = category;

	return (
		<div className="bg-white rounded-lg shadow-lg">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
				<p className="text-center text-lg font-semibold text-gray-600">
					{title}
				</p>
				<div className="mt-6 lg:mt-8 flex flex-wrap">
					{products.map((product) => (
						<div key={product.id} className="w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 px-1.5 pb-3">
							<div className="bg-copper-light shadow-sm h-full rounded-lg">
								<div className="flex flex-col items-center justify-center text-gray-500 text-base py-4 p-2">
									<div className="font-bold text-center">
										{product.name}
									</div>
									<div className="h-5 text-center text-sm text-gray-500 font-thin">
										{product.type}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default ProductCategory;
