import Logo from './Logo';

const CTA = ({ cta }) => (
	<div className="relative bg-act-darkblue">
		<div className="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
			<img
				className="w-full h-full object-cover"
				src={cta.imageUrl}
				alt=""
			/>
		</div>
		<div className="relative mx-auto max-w-md px-4 pb-12 pt-12 md:pt-0 sm:max-w-7xl sm:px-6 lg:px-8">
			<div className="md:ml-auto md:w-1/2 md:pl-10">
				<div className="w-40 h-40 mb-12">
					<Logo />
				</div>
				<h2 className="text-lg font-semibold text-gray-300 uppercase">
					{cta.subtitle}
				</h2>
				<p className="mt-2 text-white text-3xl font-bold tracking-tight sm:text-4xl sm:tracking-tight">
					{cta.title}
				</p>
				<p className="mt-3 text-lg text-gray-300">
					{cta.preview}
				</p>
				<div className="mt-8">
					<div className="inline-flex rounded-md shadow">
						<a
							href="#contact"
							className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
						>
							Get in touch
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default CTA;
