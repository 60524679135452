const Footer = () => (
	<footer className="bg-act-darkblue" aria-labelledby="footer-heading">
		<div className="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
			<div className="mt-8 border-t border-gray-700 pt-8 md:flex md:items-center md:justify-between">
				<p className="mt-8 text-base text-gray-400 md:mt-0">
					&copy;
					{' '}
					{new Date().getFullYear()}
					{' '}
					ALLCOM TRADE EU
					<br />
					{' '}
					All rights reserved.
				</p>
				<p className="mt-6 text-xs text-gray-500">
					Copyright notice:
					<br />
					All images and photos are protected by copyright.
					<br />
					<br />
					Picture credits:
					<br />
					© Despositphotos: PicterArt // depositedhar // SergeyNivens // peshkova
				</p>
			</div>
		</div>
	</footer>
);

export default Footer;
