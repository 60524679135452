import '../assets/css/main.css';
import data from '../data.json';
import Box from './partials/Box';
import Contact from './partials/Contact';
import CTA from './partials/CTA';
import Footer from './partials/Footer';
import Headline from './partials/Headline';
import Navigation from './partials/Navigation';
import ProductCategory from './partials/ProductCategory';
import Service from './partials/Service';

const App = () => {
	const {
		navigation, cta, posts, categories, services,
	} = data;

	return (
		<div className="bg-white" id="home">
			<div className="relative overflow-hidden">

				<Navigation navigation={navigation} />

				<main className="mt-12">
					<CTA cta={cta} />

					<div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
						<div className="mt-12 mx-auto max-w-md px-2 sm:px-4 sm:max-w-3xl lg:px-8 lg:max-w-7xl">

							<div className="flex flex-wrap">
								{posts.map((post) => (
									<div key={post.id} className="w-full md:w-1/2 lg:w-1/3 px-1 sm:px-4 mb-8">
										<Box post={post} />
									</div>
								))}
							</div>

							<div className="pt-32" id="services">
								<Headline title="Services" subline="We Buy, We Sell and Trade Metals" />
								<ul className="flex flex-wrap">
									{services.map((service) => (
										<div key={service.id} className="w-full sm:w-full md:w-1/2 lg:w-1/3 px-1 sm:px-4 mb-16">
											<Service service={service} />
										</div>
									))}
								</ul>
							</div>

							<div className="px-1 sm:px-4 mb-8 pt-32" id="products">
								<Headline title="Products" />
								<div className="mb-32">
									{categories.map((category) => (
										<ProductCategory key={category.id} category={category} />
									))}
								</div>
							</div>

							<div className="px-1 sm:px-4 mb-8" id="contact">
								<Headline title="Contact" />
								<Contact />
							</div>

						</div>
					</div>

				</main>

				<Footer />

			</div>
		</div>
	);
};

export default App;
