const Box = ({ post }) => (
	<div className="flex h-full flex-col rounded-lg shadow-lg overflow-hidden">
		<div className="flex-shrink-0">
			<img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
		</div>

		<div className="flex-1 bg-white p-6 flex flex-col justify-between h-full">
			<div className="flex-1">
				<p className="text-sm font-medium text-copper-main uppercase">
					{/* <a href={post.category.href} className="hover:underline"> */}
					{post.subtitle}
					{/* </a> */}
				</p>
				<a href={post.href} className="block mt-2">
					<p className="text-xl font-semibold text-gray-900">{post.title}</p>
					<p className="mt-3 text-sm font-thin text-gray-500 leading-6">{post.preview}</p>
				</a>
			</div>
			{/* <div className="mt-6 flex items-center">
						<div className="flex-shrink-0">
						  <a href={post.author.href}>
							<img className="h-10 w-10 rounded-full" src={post.author.imageUrl} alt={post.author.name} />
						  </a>
						</div>
						<div className="ml-3">
						  <p className="text-sm font-medium text-gray-900">
							<a href={post.author.href} className="hover:underline">
							  {post.author.name}
							</a>
						  </p>
						  <div className="flex space-x-1 text-sm text-gray-500">
							<time dateTime={post.datetime}>{post.date}</time>
							<span aria-hidden="true">&middot;</span>
							<span>{post.readingLength} read</span>
						  </div>
						</div>
					  </div> */}
		</div>
	</div>
);

export default Box;
