import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import Logo from './Logo';

const Contact = () => (
	<div className="relative">
		<div className="relative max-w-7xl">
			<div className="flex flex-col sm:flex-row w-full bg-gray-50 pb-4 px-4">
				<div className="max-w-lg mx-0 sm:mx-auto sm:flex sm:flex-row">
					<dl className="text-base text-gray-500">
						<div>
							<dt className="sr-only">
								Postal address
							</dt>
							<dd>
								<p className="mb-3 w-full">
									<Logo />
								</p>
								<p>17, 11th August Str.</p>
								<p className="mb-2">Sofia 1000, Bulgaria</p>
							</dd>
						</div>
					</dl>
					<div className="sm:ml-24 sm:mt-8">
						<div className="mt-6 sm:mt-0">
							<dt className="sr-only">Phone number</dt>
							<dd className="flex">
								<PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
								<span className="ml-3">
									<a className="text-copper-main" href="tel:+33659714601">+33 6 59 71 46 01</a>
								</span>
							</dd>
						</div>
						<div className="mt-2">
							<dt className="sr-only">Email</dt>
							<dd className="flex">
								<MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
								<span className="ml-3">
									<a className="text-copper-main" href="mailto:uzankel@allcomtrade.eu.com">uzankel@allcomtrade.eu.com</a>
								</span>
							</dd>
						</div>
						<div className="mt-2">
							<dt className="sr-only">Email</dt>
							<dd className="flex">
								<MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
								<span className="ml-3">
									<a className="text-copper-main" href="mailto:cargo@allcomtrade.eu.com">cargo@allcomtrade.eu.com</a>
								</span>
							</dd>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Contact;
