import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Fragment } from 'react';
import Logo from './Logo';

const Navigation = ({ navigation }) => (
	<Popover as="header" className="z-40 fixed w-full">
		<div className="bg-act-darkblue py-3">
			<nav className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6" aria-label="Global">

				<div className="flex items-center flex-1">
					<div className="flex items-center justify-between w-full md:w-auto">

						<div className="-mr-2 flex items-center md:hidden">
							<Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
								<span className="sr-only">Open main menu</span>
								<MenuIcon className="h-6 w-6" aria-hidden="true" />
							</Popover.Button>
						</div>
					</div>

					<div className="hidden space-x-8 md:flex md:ml-10">
						{navigation.map((item) => (
							<a
								key={item.name}
								href={item.href}
								className="text-base font-medium text-white hover:text-gray-300"
							>
								{item.name}
							</a>
						))}
					</div>
				</div>

			</nav>
		</div>

		<Transition
			as={Fragment}
			enter="duration-150 ease-out"
			enterFrom="opacity-0 scale-95"
			enterTo="opacity-100 scale-100"
			leave="duration-100 ease-in"
			leaveFrom="opacity-100 scale-100"
			leaveTo="opacity-0 scale-95"
		>
			<Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
				<div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
					<div className="px-5 pt-4 flex justify-between">
						<div>
							<div className="w-24 rounded">
								<a href="#home" className="outline-none">
									<Logo />
								</a>
							</div>
						</div>
						<div className="-mr-2">
							<Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
								<span className="sr-only">Close menu</span>
								<XIcon className="h-6 w-6" aria-hidden="true" />
							</Popover.Button>
						</div>
					</div>
					<div className="pt-5 pb-6">
						<div className="px-2 space-y-1">
							{navigation.map((item) => (
								<a key={item.name} href={item.href} className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50">
									{item.name}
								</a>
							))}
						</div>
					</div>
				</div>
			</Popover.Panel>
		</Transition>
	</Popover>
);

export default Navigation;
